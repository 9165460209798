import axios from "axios";
import Links from "../constants/Links";

/**
 * This is the Auth Biometric hook for handling system biometric authentication
 * (C) 2021-2023
 *
 * */

const Biometric = {
    /**
     * handle finger print enrollment for user
     * @param {object} data information to be enrolled
     * @returns {Promise<object>}
     */
    enrollFingerPrint: async (data) => {
        return axios.post(Links.biometric.enrollFingerPrint, data)
    },

    enrollFingerPrintBulk: async (data) => {
        return axios.post(Links.biometric.enrollFingerPrintBulk, data)
    },

    /**
     * Check if a user has been enrolled for biometric
     * @param {string} userId userId of the user
     * @returns {Promise<object>}
     */
    checkEnrollment: async (userId) => {
        return axios.get(Links.biometric.checkEnrollment + userId)
    },

    /**
     * verify a user with biometric
     * @param {object} data information to be verified
     * @returns {Promise<boolean>}
     */
    verify: async (data) => {
        return axios.post(Links.biometric.verify, data)
    }
}

export default Biometric
