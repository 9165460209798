<template>
  <v-text-field :placeholder="placeholder" v-on="$listeners" v-bind="$attrs" :value="value" @input="$emit('input', $event)"></v-text-field>
</template>

<script>
export default {
  name: "XTextField",
  props: ['value', 'placeholder'],
}
</script>

<style scoped>

</style>
