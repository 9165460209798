import axios from "axios";
import Links from "../constants/Links";


/**
 *  This is the Programmes API hook, for handling programmes (C) 2021-2023
 */

const Programmes = {
    /**
     * @description creates a new programme
     * @param {object} data an object containing the following properties
     * departmentId(Department ID), programmeTypeId(programme type id), name(string), code(string), programmeDuration(number),
     * minimumSemesters(numbers), maximumSemesters(numbers), revenueCode(string), availableOnline(checkbox true or false),
     * levelId (Level ID. only required if the programme type does not support mode of entry. that can be check using the foll.
     * prop of programme type object: modeOfEntryEnabled ),
     * awardId (id of the award for the programme)
     * @returns the newly created programme
     */
    create: async (data) => {
        return axios.post(Links.programmes.create, data)
    },

    /**
     * @description updates an existing programme
     * @param {object} data an object containing the following properties
     * departmentId(Department ID), programmeTypeId(programme type id), name(string), code(string), programmeDuration(number),
     * minimumSemesters(numbers), maximumSemesters(numbers), revenueCode(string), availableOnline(chewckbox true or false),
     * levelId (Level ID. only required if the programme type does not support mode of entry. that can be check using the foll. prop of programme type object: modeOfEntryEnabled ),
     * awardId (id of the award for the programme)
     * @returns the newly created programme
     */
    update: async (id, data) => {
        return axios.put(Links.programmes.update + '/' + id, data);
    },

    /**
     * fetch all programmes
     * @returns {Promise<any[]>}
     */
    all: async () => {
        return axios.get(Links.programmes.all)
    },

    /**
     * @description Fetchs all programmes under given programme type that are available for online application
     * @param {string} programmeTypeId the programme type to fetch for
     * @returns {Promise<any[]>}
     */
    allAvailable: async (programmeTypeId) => {
        return axios.get(Links.programmes.allAvailable + '?programme_type=' + programmeTypeId)
    },

    /**
     * fetch all programmes for the department
     * @param {string} depId department id
     * @returns {Promise<any[]>}
     */
    allForDep: async (depId) =>{
        return axios.get(Links.programmes.allFor + depId)
    },

    /**
     * fetch all programmes under programmeType
     * @param {string} programmeTypeId programmeTypeId
     * @returns {Promise<any[]>}
     */
    allForProgrammeType: async (programmeTypeId) => {
        return axios.get(Links.programmes.allForProgrammeType + programmeTypeId)
    },

    /**
     * fetch all information depending on the selected programmetype and department
     * @param {string} programmeTypeId programmeType
     * @param {string} departmentId department
     * @returns {Promise<any[]>}
     */
    allForProgrammeTypeAndDepartment: async (programmeTypeId, departmentId) => {
        return axios.get(Links.programmes.all + '/' + programmeTypeId + '/' + departmentId)
    },

    /**
     * fetch all programmes under a department
     * @param {string} departmentId department id
     * @returns {Promise<any[]>}
     */
    allForDepartment: async (departmentId) => {
        return axios.get(Links.programmes.allForDepartment + departmentId)
    }
}

export default Programmes
