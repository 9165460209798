import axios from "axios";
import Links from "../constants/Links";


/**
 * This is the Auth API hook for handling system authentication
 * (C) 2021-2023
 *  
 * */

const Auth = {
    /**
     * handles logging into the system
     * @param {object} data the user information
     * @returns {Promise<object>}
     */
    login: async (data) => {
        return axios.post(Links.auth.login, data)
    },

    /**
     * fetching of user bio-data information
     * @param {object} data the user bio data information
     * @returns {Promise<object>}
     */
    bioAuth: async (data) => {
        return axios.post(Links.auth.bioAuth, data)
    },

    /**
     * update user password
     * @param {object} data 
     * @returns {Promise<object>}
     */
    updatePassword: async (data) => {
        return axios.put(Links.auth.updatePassword, data)
    },

    /**
     * handles enabling of Two-factor authentication 
     * @param {object} data
     * @returns {Promise<boolean>}
     */
    enableMfa: async (data) => {
        return axios.put(Links.auth.enableMfa, data)
    },

    /**
     * Disable Two-factor authentication
     * @param {object} data 
     * @returns {Promise<boolean>}
     */
    disableMfa: async (data) => {
        return axios.put(Links.auth.disableMfa, data)
    },

    /**
     * Request authentication code (email or phone number)
     * @param {object} data 
     * @returns {Promise<object>}
     */
    requestCode: async (data) => {
        return axios.put(Links.auth.requestCode, data)
    },

    /**
     * verify Two-factor authentication
     * @param {object} data 
     * @returns {Promise<object>}
     */
    verifyMfa: async (data) => {
        return axios.put(Links.auth.verifyMfa, data)
    },

    /**
     * confirm Two-factor authentication
     * @param {object} data 
     * @returns {Promise<object>}
     */
    confirmEnableMFA: async (data) => {
        return axios.put(Links.auth.confirmEnableMFA, data)
    },

    /**
     * unlock user account
     * @param {object} data 
     * @returns {Promise<object>}
     */
    unlock: async (data) => {
        return axios.post(Links.auth.unlock, data)
    },

    /**
     * 
     * @param {object} data 
     * @returns {Promise<object>}
     */
    updateLock: async (data) => {
        return axios.post(Links.auth.updateLock, data)
    },

    /**
     * handle sending of OTP(one-time-password)
     * @param {object} data 
     * @returns {Promise<object>}
     */
    sendOtp: async (data) => {
        return axios.post(Links.auth.sendOtp, data)
    },

    /**
     * verify OTP via email or phone number
     * @param {object} data 
     * @returns {Promise<object>}
     */
    verifyOtp: async (data) => {
        return axios.post(Links.auth.verifyOtp, data)
    },

    /**
     * update forgotten password
     * @param {object} data 
     * @returns {Promise<object>}
     */
    updatePasswordForgot: async (data) => {
        return axios.post(Links.auth.updatePasswordForgot, data)
    },

    /**
     * handles switching of user account i.e a superrior user can login to other account to view their information
     * @param {object} data 
     * @returns {Promise<boolean>}
     */
    switchUser: async (data) => {
        return axios.post(Links.auth.switchUser, data)
    },

    /**
     * the opposite of switchuser method above
     * @param {object} data 
     * @returns {Promise<boolean>}
     */
    revertSwitchUser: async (data) => {
        return axios.post(Links.auth.revertSwitchUser, data)
    }
}

export default Auth
