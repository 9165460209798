<template>
  <div>
    <v-navigation-drawer v-model="drawer" :mini-variant.sync="showMini" :permanent="isLarge"  color="primaryDark" app>
      <v-list dense dark class="d-flex flex-column">
        <v-list-item class="pb-5" dense link key="Dashboard" to="/dashboard" v-if="!showMini">
          <v-list-item-icon>
            <v-img
                width="60"
                contain
                height="60"
                :src="image"
            />
          </v-list-item-icon>
          <v-list-item-content class="my-0 pb-0">
            <div>
              <v-list-item-title class="my-0">Welcome back!</v-list-item-title>
              <v-list-item-subtitle class="my-0">{{userId.toUpperCase()}}</v-list-item-subtitle>
              <v-list-item-subtitle class="my-0">{{(new Date()).toDateString()}}</v-list-item-subtitle>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="!showMini"/>


        <v-list-item dense v-for="item in items.managements" :key="item.title" :to="item.url" class="py-0">
          <v-list-item-icon>
            <v-icon color="white" :size="iconSize">{{ item.icon }}</v-icon>
          </v-list-item-icon>


          <v-list-item-content>
            <v-list-item-title class="white--text">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-menu bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-list-item class="py-0" v-on="on">
              <v-list-item-icon>
                <v-icon color="white" :size="iconSize">{{ 'mdi-cog' }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white--text">{{ 'Settings' }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-card>
            <v-list light>
              <v-list-item v-for="item in items.settings" :key="item.title" :to="item.url">
                <v-list-item-icon>
                  <v-icon color="primary" :size="iconSize">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import Defaults from "@/shared/Defaults";

export default {
  name: "Sidebar",
  components: {

  },
  props: {
    showSecondarySidebar: {
      type: Boolean,
      default: true
    },

    sidebarClassName: {
      type: String,
      default: ''
    },

    open: Boolean,

    mini: Boolean,

    items: Object
  },


  computed: {
    isLarge: function() {
      return this.$vuetify.breakpoint.lg
    },

    showMini: function() {
      return this.open && this.isLarge
    },

    userId: function(){
      if(!this.getUser){
        return ''
      }

      return this.getUser.user ? this.getUser.user.userId : this.getUser.userId;
    },

    ...mapGetters(['getUser'])
  },

  watch: {
    open: function(newVal) {
      this.iconSize = newVal ? 14 : 18;
      this.drawer = newVal;
    },

    getUser: function(newVal){
      if(newVal != null){
        this.image = `${Defaults.api}/profile-picture/fetch?user=${this.getUser.userId}`;
      }
    },
  },


  data: () => ({
    showOptionsMenu: false,
    drawer: true,
    logo: '',
    iconSize: 18,
    image: ''
  }),

  created(){
    this.image = `${Defaults.api}/profile-picture/fetch?user=${this.getUser.userId}`;
  }
}
</script>


<style lang="scss" scoped>
.lafia-sidebar {
  display: flex;
  min-height: 0;
  height: 100%;
  width: fit-content;

}


.secondary-sidebar {
  overflow-y: auto;
  height: 100%;
}
</style>
