<template>
  <v-dialog v-model="show" :transition="transition" :scrollable="scrollable" :persistent="persistent" :max-width="maxwidth" :fullscreen="fullscreen" retain-focus :disabled="disabled">
    <slot />
  </v-dialog>
</template>

<script>
export default {
  name: "XDialog",
  props: {
    maxwidth: {
      type: String,
      default: ''
    },

    transition: {
      type: String, 
      default: ''
    },

    scrollable: {
      type: Boolean, 
      default: false
    },

    fullscreen: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    show: {
      type: Boolean,
      default: false
    },

    persistent: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
