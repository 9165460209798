import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            options: {
                customProperties: true,
            },

            light: {
                primary: '#14C38E',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#5A75F8',
                success: '#07C180',
                warning: '#FF9931',
                black: "#223345",
                drawerBg: "#016b4b",
                basic: "#7D9EB5",
                drawerLinkVisited: "#CAE1EE",
                basicLight: '#b2c1ce',
                transcriptPrimary:"#85C226",
            },
        },
    },


});
