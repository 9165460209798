import Vue from 'vue'
import Verification from './Verification.vue'
import vuetifyNsuk from "../plugins/vuetify";
import vuetifyLafia from "../plugins/lafiaPlugin";
import AppNsuk from "./Verification.vue";
import AppLafia from "./lafia/App.vue";
import router from './routes/index';
import store from "./store";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import AppTypes from "@/shared/constants/apptypes";
import Defaults from '@/shared/Defaults';
import axios from 'axios';
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";


if (window.API) {
    // set default api
    Defaults.api = window.API
    // set up axios api
    axios.defaults.baseURL = window.API
} else {
    axios.defaults.baseURL = Defaults.api // use api for test
}


let nsuk = AppTypes.CURRENT === AppTypes.NSUK;


Vue.config.productionTip = false;
if(nsuk)
    Vue.use(VueToast);
else
    Vue.use(Toast, {
        transition: "Vue-Toastification__fade",
        maxToasts: 20,
        newestOnTop: true
    })

new Vue({
  vuetify: nsuk ? vuetifyNsuk : vuetifyLafia,
  router,
  store,
  render: (h) => h(nsuk ? AppNsuk : AppLafia),
}).$mount("#app");
