import axios from "axios";
import Links from "../constants/Links";

/**
 * This is the department API hook for handling departments in the system
 * (C) 2021-2023
 */
const Department = {
    /**
     * To create a department
     * @param {object} data 
     * @returns {Promise<object>}
     */
    create: async (data) => {
        return axios.post(Links.department.create, data)
    },

    /**
     * To update a department
     * @param {object} data 
     * @returns {Promise<object>}
     */
    update: async (data) => {
        return axios.put(Links.department.update, data);
    },

    /**
     * To delete a department base on the selected id
     * @param {string} id id of the department
     * @returns {Promise<object>}
     */
    delete: async (id) => { 
        return axios.delete(Links.department.delete + '/' + id)
    },

    /**
     * retrive all departments
     * @returns {Promise<any[]>}
     */
    all: async () => {
        return axios.get(Links.department.all)
    },

    /**
     * retrive all departments base on selected faculty(id)
     * @param {string} faculty 
     * @returns {Promise<any[]>}
     */
    allForFaculty: async (faculty) => {
        return axios.get(Links.department.allForFaculty + faculty)
    },

    /**
     * retrive all department tied to HOD
     * @returns {Promise<any[]>}
     */
    allWithHods: async () => {
        return axios.get(Links.department.allWithHods)
    },

    /**
     * retrive department tied to the current user loogged-in
     * @param {string} user(userid)
     * @returns {Promise<any[]>}
     */
    allForCurrentUser: async (user) => {
        return axios.get(Links.department.allForCurrentUser + '?staff=' + user)
    },

    /**
     * retrive the current user and its faculty
     * @param {string} user(userid)
     * @param {string} faculty 
     *@returns {Promise<any[]>}
     */
    allForCurrentUserAndFaculty: async (user, faculty) => {
        return axios.get(Links.department.allForCurrentUserAndFaculty + '?staff=' + user + '&faculty=' + faculty)
    },

    /**
     * retrive all HOD's of every department
     * @returns {Promise<any[]>}
     */
    allHods: async () => {
        return axios.get(Links.department.allHods)
    },

    /**
     * ready to set signature
     * @param {object} data 
     * @returns {Promise<any[]>}
     */
    setSignature: async (data) => {
        return axios.post(Links.department.setSignature, data)
    },

    /**
     * get HOD of a department with the department (id)
     * @param {string} departmentId
     * @returns {Promise<any[]>}
     */
    getHod: async (departmentId) =>{
        return axios.get(Links.department.getHod + departmentId)
    },

    /**
     * request for result access 
     * @param {string} departmentId 
     * @returns {Promise<any[]>}
     */
    openResultAccess: async (departmentId) => {
        return axios.put(Links.department.openAccess + departmentId)
    },

    /**
     * request for closure of result access
     * @param {string} departmentId 
     * @returns {Promise<any[]>}
     */
    closeResultAccess: async (departmentId) => {
        return axios.put(Links.department.closeAccess + departmentId)
    },

    /**
     * Release student for graduation service
     * @param {string} programmeTypeId 
     * @param {string} departmentId 
     * @returns {Promise<any[]>}
     */
    graduationService: async (programmeTypeId, departmentId) => {
        return axios.put(Links.department.graduationService + programmeTypeId + '/' + departmentId)
    },

    /**
     * view semester settings
     * @param {string} department 
     * @param {string} semester 
     * @returns {Promise<any[]>}
     */
    getSemesterSettings: async (departmentId, semesterId) => {
        return axios.get(Links.department.getSemesterSettings + '?department=' + departmentId + '&semester=' + semesterId)
    },

    /**
     * toggle for result uploaad, either true or false
     * @param {string} department 
     * @param {string} semester 
     * @returns {Promise<any[]>}
     */
    toggleResultUpload: async (department, semester) => {
        return axios.put(Links.department.toggleResultUpload + '/' + department + '/'+semester)
    },

    /**
     * toggle for result checking
     * @param {string} department 
     * @param {string} semester 
     * @returns 
     */
    toggleResultChecking: async (department, semester) => {
        return axios.put(Links.department.toggleResultChecking + '/' + department + '/'+semester)
    },

    /**
     * retrive added subjects
     * @param {string} department 
     * @returns 
     */
    getAddedSubjects: async (department) => {
        return axios.get(Links.department.getAddedSubjects + '/' + department)
    },

    /**
     * add a subject
     * @param {string} departmentId
     * @param {object} data 
     * @returns 
     */
    addSubject: async (departmentId, data) => {
        return axios.put(Links.department.addSubject + '/' + departmentId, data)
    },

    /**
     * Add all subject required by the department
     * @param {string} departmentId 
     * @param {object} data 
     * @returns 
     */
    addAllSubjects: async (departmentId, data) => {
        return axios.put(Links.department.addAllSubject + '/' + departmentId, data)
    },

    /**
     * Remove subject
     * @param {string} departmentId 
     * @param {object} data 
     * @returns 
     */
    removeSubject: async (departmentId, data) => {
        return axios.put(Links.department.removeSubject + '/' + departmentId, data)
    },

    /**
     * Make a subject compulsory for the students
     * @param {string} itemId 
     * @returns 
     */
    toggleSubjectCompulsory: async (itemId) => {
        return axios.put(Links.department.toggleCompulsory + '/' + itemId)
    },

    /**
     * update subject grades
     * @param {string} itemId 
     * @param {object} data 
     * @returns 
     */
    updateSubjectsGrades: async (itemId, data) => {
        return axios.put(Links.department.updateSubjectsGrades + '/' + itemId, data)
    },

    /**
     * retrive subjects for the applicant
     * @param {string} departmentId
     * @returns 
     */
    getSubjectsForApplications: async (departmentId) => {
        return axios.get(Links.department.getAllSubjectsForApplication + '/' + departmentId)
    },
}

export default Department