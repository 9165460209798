<template>
  <!-- App.vue -->

  <v-app style="background-color: #f5f5f5">
    <v-app-bar :app="true" elevate-on-scroll color="white" class="flex-shrink-1" dense>

      <v-list-item-icon class="my-auto">
        <img class="my-auto" width="32px" height="32px"  alt="lg" :src="logo" />
        <!--        <v-icon color="drawerBg" size="32">mdi-school</v-icon>-->
      </v-list-item-icon>


      <div class="font-weight-bold" v-if="!isMobile">
        {{ global.shortName }} - Application
      </div>
      <div class="font-weight-bold" v-else>
        {{ global.shortName }} - Application
      </div>

      <v-spacer></v-spacer>
      <div class="nav-actions" v-if="loggedIn">
        <router-link to="/dashboard" class="ml-3" active-class="basic--text" v-if="!isMobile">Dashboard</router-link>
        <router-link to="/application" class="ml-3" active-class="basic--text" v-if="!isMobile">Application</router-link>
        <router-link to="/data-correction" class="ml-3" active-class="basic--text" v-if="!isMobile">Data Correction</router-link>
        <!-- un-comment this out -->
        <!-- <router-link to="/verify" class="ml-3" active-class="basic--text" v-if="!isMobile">Verify</router-link> -->
        <router-link to="/change-program" class="ml-3" active-class="basic--text"
          v-if="!isMobile && canChangeProgramme">Change of programme</router-link>

        <template v-if="isMobile">
          <v-menu close-on-click class="">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-2" v-on="on" v-bind="attrs">mdi-chevron-down</v-icon>
            </template>

            <v-list v-if="isMobile">
              <v-list-item key="dashboard" link to="dashboard">Dashboard</v-list-item>
              <v-list-item key="application" link to="application">Application</v-list-item>
              <v-list-item key="change-programe" link to="change-program" v-if="canChangeProgramme">Change programme
              <v-list-item key="data-correction" link to="data-correction">Data Correction</v-list-item>
              </v-list-item>
              <v-list-item link @click="exitSuper" v-if="getSuper">
                <v-list-item-title>{{ 'Logout current' }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-for="(item, index) in ['Logout']" :key="index" link>
                <v-list-item-title @click="logout">{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <v-menu close-on-click class="ml-0 pl-0" v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" v-bind="attrs" v-on="on" text :class="{ 'ml-0 p-0 btn mr-0': true, 'pr-0': isMobile }">
              {{
                  getUser != null ? getUser.lastName != null ? getUser.lastName
                    : "Not Set"
                    : "Not Set"
              }}<v-icon class="ml-2">mdi-chevron-down</v-icon>
            </v-btn>

          </template>

          <v-list v-if="!isMobile">
            <v-list-item link @click="exitSuper" v-if="getSuper">
              <v-list-item-title>{{ 'Logout current' }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-for="(item, index) in ['Logout']" :key="index" link @click="logout">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="flex-grow-1">
      <!-- Provides the application the proper gutter -->
      <v-container style="height: 100%">
        <router-view />
      </v-container>
    </v-main>


    <loading-dialog :show="loading"></loading-dialog>
    <message-box :show="messageShow" :message="message" :type="messageType"
      v-on:close-message="messageShow = !messageShow"></message-box>
  </v-app>
</template>

<script>
import Defaults from "@/shared/Defaults";
import Links from "@/shared/constants/Links";
import { mapActions, mapGetters } from "vuex";
import LoginUtil from "../login/LoginUtil";
import Roles from "@/shared/Roles";
import ErrorResolver from "@/shared/ErrorResolver";
import LoadingDialog from "@ui/components/LoadingDialog";
import MessageBox from "@ui/components/MessageBox";
import Api from "@/main/api";
import StudentStatus from "@/shared/StudentStatus";

export default {
  name: "App",
  components: { MessageBox, LoadingDialog },

  data: () => ({
    links: null,
    mini: false,
    viewOption: 1,
    image: "",
    counter: 0,
    logo: Defaults.api + Links.global.logo,
    messageShow: false,
    loading: false,
    message: "",
    messageType: "",
    global: {},
  }),

  methods: {
    logout() {
      LoginUtil.resetAccessToken();
      LoginUtil.resetRefreshToken();
      window.location.href = '/login';
    },

    checkAndLoadData() {
          if (LoginUtil.checkAuth()) {
            // not logged in
            // make sure it's am admin
            this.loading = true;
            this.getInitialData()
              .then((res) => {
                let user = res.data.userData.user;
                if (!Roles.contains(user.roles, Roles.Applicant)) {
                  LoginUtil.resetAccessToken();
                  LoginUtil.resetRefreshToken();
                  window.location.href = "/application/";
                }

                this.$store.commit("setCurrentUser", res.data.userData);
                this.$store.commit("setInformation", res.data.information);
                this.$store.commit("setUTMEDetails", res.data.utmeDetails);
                this.$store.commit("setSSCEDetails", res.data.ssceDetails);
                this.$store.commit("setNYSCDetails", res.data.nyscDetails);
                this.$store.commit("setQualificationDetails", res.data.qualificationCertificate);
                this.$store.commit("setApplicationFee", res.data.applicationFee);
                this.$store.commit("setAdmissionFee", res.data.admissionFee);
                this.$store.commit("setScreeningFee", res.data.screeningFee);
                this.$store.commit("setScratchCards", res.data.scratchCards);

                // if user has not started application already
                if (user.firstName != null) {
                  // take to application

                  if(this.$route.path !== "/dashboard"){
                    this.$router.push('dashboard');
                  }
                }
              })
              .catch((err) => ErrorResolver.checkErrors(this, err))
              .finally(() => (this.loading = false));
      }
    },

    changeTitle() {
      Api.schools.getDominant().then((res) => {
          document.title = res.data.name // change the page title
        }).catch((err) => ErrorResolver.checkErrors(err, err))
    },

    exitSuper(){
      this.loading = true
      Api.auth.revertSwitchUser({
        user: this.getUser.userId
      })
          .then(res => {
            window.close() // close tab
            // store token
            // LoginUtil.setAccessToken(res.data.jwt)
            // LoginUtil.setRefreshToken(res.data.refreshToken);
            // // change location
            // window.location.href = "/main"
          }).catch(err => ErrorResolver.checkErrors(this, err))
          .finally(() => this.loading = false)
    },

    ...mapActions(["getInitialData"]),
  },



  watch: {},

  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },

    loggedIn: function () {
      return this.getUser != null;
    },

    canChangeProgramme: function () {
      return this.getUser != null && (this.getUser.admission.applicationType.changeProgrammesStatus.includes(this.getUser.studentStatus)
          || this.getUser.studentStatus === StudentStatus.PROGRAMME_CHANGE.text) && !this.getUser.migrated
    },

    ...mapGetters(["getUser", "getSuper"]),
  },

  created() {
    Api.global
      .fetch()
      .then((res) => {
        this.global = res.data;
      })
      .catch((err) => ErrorResolver.checkErrors(this, err));

    this.changeTitle();
    this.checkAndLoadData();
  },
};
</script>


<style lang="css">
.nav-actions>* {
  margin-left: 16px;
}

.btn {
  text-transform: none;
}

.btn:hover {
  background-color: white;
}

a {
  text-decoration: none;
}

body {
  background-color: #f5f5f5;
}
</style>
