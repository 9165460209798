
<template>
    <progress :value="progress"></progress>
</template>

<script>
import { ref, onUnmounted, readonly } from "vue";

export default {
    name: "ProgressBar", 
    setup() {
        const progress = ref(0); 

        /**
         * update progress continously
         */
        const updateProgress = () => {
            const bodyHeight = document.body.clientHeight - document.documentElement.clientHeight; 
            const position = window.scrollY; 
            progress.value = position / bodyHeight;         
        }


        window.addEventListener('scroll', updateProgress); 


        //perform DOM cleanup 
        onUnmounted(() => {
            window.removeEventListener('scroll', updateProgress); 
        })


        return { 
            progress: readonly(progress)
        }
    }
}
</script>

<style lang="scss">
progress {
    position: fixed;
    appearance: none;
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 5px; 
    z-index: 99999;
    background-color: transparent;
    border-color: transparent;

    ::-webkit-progress-bar {
        background-color: transparent;
    }


    ::-webkit-progress-value {
        background-color: #85C226;
    }
}
</style> 