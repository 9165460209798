<template>
  <v-sheet  elevation="1" >
    <v-list-item two-line >
      <v-list-item-content>
        <v-list-item-title class="mb-2">{{ header }}</v-list-item-title>
        <v-list-item-subtitle>{{ content }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon class="ml-auto my-auto">
        <v-icon :color="color ? color : 'primary'" size="32">{{ 'mdi-'.concat(icon) }}</v-icon>
      </v-list-item-icon>
    </v-list-item>
  </v-sheet>
</template>

<script>
export default {
  name: "BasicInfoDisplay",
  props: ['header', 'content', 'icon', 'color'], 
  // props: {
  //   header: String, 
  //   content: Number, 
  //   icon: String, 
  //   color: String,
  // }, 

  computed: {
    isMobile: function() {
      return this.$vuetify.breakpoint.smAndDown
    }, 

    isMediumAndUp: function() {
      return this.$vuetify.breakpoint.mdAndUp
    }
  }

}
</script>




<style lang="css" scoped>



/** Mobile Screen View */

</style>