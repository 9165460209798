<template>
    <div class="text-center loading">
        <v-overlay  v-model="show" hide-overlay persistent>
            <div class="">
                <v-progress-circular :size="100" width="7" color="#85C226" indeterminate/>
                <p class="mt-5">{{message || 'Loading...'}}</p>
            </div>
        </v-overlay>
    </div>
</template>


<script>
export default {
    name: "Loading",
    props: {
        show: Boolean,
        message: String,
    },
}
</script>


<style lang="scss">

.loading {
    display: grid;
    place-items: center;
    z-index: 999; 

    p {
        color: white;
    }
}

// .loading {
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     overflow: hidden;
//     align-items: center;
//     justify-content: center;
//     z-index: 999;

//     p {
//         color: white;
//     }
// }
</style>