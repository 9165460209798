
import axios from "axios";
import Links from "../constants/Links";


/**
 * This is the PortalDocument API hook for handling portal document
 * (C) 2021-2023
 *
 * */

const portalDocument = {
  /**
   * create a single portal document
   * @param {object} data
   * @returns {Promise<FormData>}
   */
  create: async (data) => {
    return axios.post(Links.portalDocument.create, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  },

  /**
   * update a single portal document
   * @param {string} id
   * @returns {Promise<object>}
   */
  update: async (id, data) => {
    return axios.post(Links.portalDocument.update + '/' + id, data)
  },

  /**
   * delete portal a single portal document
   * @param {string} id
   * @returns {Promise<object>}
   */
  delete: async (id) => {
    return axios.delete(Links.portalDocument.delete + '/' + id)
  },

  /**
   * fetch all portal document base on selected programme_type
   * @param {string} programme_type selected programmetype
   * @param {number} page the default page default 1
   * @param {number} size the defaultn size 10
   * @returns {Promise<any[]>}
   */
  all: async (programme_type, page, size) => {
    return axios.get(Links.portalDocument.all + '?page=' + page + "&size=" + size  + (programme_type ? ('&programme_type=' + programme_type) : ""))
  },

  download: async (link) => {
    return axios.get(link, {
      responseType: "blob"
    })
  },
};

export default portalDocument;
