import axios from "axios";
import Links from "../constants/Links";

/**
 * /**
 * This is the Applicants API hook for handling application request in the system
 * (C) 2021-2023
 *
 * */

const Applicants = {
    /**
     * Fetch all applicants depending on the choosing reqparams { session, faculty, department, programme, country, state, lga, gender }
     * @param {string} reqParams
     * @param {string} page page number
     * @param {string} itemsPerPage size of the page e.g 10
     * @returns
     */
    all: async(reqParams, page, itemsPerPage) => {
        let params = '?session=' + reqParams.session
        if(reqParams.status){
            params = params +  '&student_status=' + reqParams.status
        }
        if(reqParams.faculty){
            params = params +  '&faculty=' + reqParams.faculty
        }
        if(reqParams.department){
            params = params +  '&department=' + reqParams.department
        }
        if(reqParams.programme){
            params = params +  '&programme=' + reqParams.programme
        }
        if(reqParams.country){
            params = params +  '&country=' + reqParams.country
        }
        if(reqParams.state){
            params = params +  '&state=' + reqParams.state
        }
        if(reqParams.lga){
            params = params +  '&lga=' + reqParams.lga
        }
        if(reqParams.gender){
            params = params +  '&gender=' + reqParams.gender
        }

        return axios.get(Links.applicants.all + params + "&page="+page+"&size="+itemsPerPage);
    },

    /**
     * validate UTME details
     * @param {object} data
     * @returns {Promise<object>}
     */
    validateUTME: async (data) => {
        return axios.post(Links.applicants.validateUTME, data)
    },

    /**
     * Fetch initial data
     * @returns {Promise<object>}
     */
    getInitialData: async () =>  {
        return axios.get(Links.applicants.getInitialData);
    },

    /**
     * to register applicant into the system
     * @param {object} data information to be updated
     * @returns {Promise<object>}
     */
    register: async (data) => {
        return axios.post(Links.applicants.register, data)
    },

    /**
     * update applicant details
     * @param {string} applicant
     * @param {object} data
     * @returns {Promise<object>}
     */
    personalDetailsUpdate: async (applicant, data) => {
        return axios.put(Links.applicants.personalDetailsUpdate + '/' + applicant, data)
    },

    /**
     * update applicant contact details
     * @param {string} applicant userId of the applicant
     * @param {object} data information to be updated
     * @returns {Promise<object>}
     */
    contactDetailsUpdate: async (applicant, data) => {
        return axios.put(Links.applicants.contactDetailsUpdate + '/' + applicant, data)
    },

    /**
     * update nextofkin information
     * @param {string} applicant userId of the applicant
     * @param {object} data information to be updated
     * @returns {Promise<object>}
     */
    nextOfKinUpdate: async (applicant, data) => {
        return axios.put(Links.applicants.nextOfKinUpdate + '/' + applicant, data)
    },

    /**
     * update SSCE(WAEC OR NECO) information
     * @param {string} applicant userId of the applicant
     * @param {object} data information to be updated
     * @returns
     */
    ssceUpdate: async (applicant, data) => {
        return axios.put(Links.applicants.ssceUpdate + '/' + applicant, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    /**
     * update UTME details
     * @param {string} applicant userId of the applicant
     * @param {object} data information to be updated
     * @returns
     */
    utmeUpdate: async (applicant, data) => {
        return axios.put(Links.applicants.utmeUpdate + '/' + applicant, data)
    },

    /**
     * update NYSC details
     * @param {string} applicant the applicant userId
     * @param {object} data information to be updated
     * @returns
     */
    nyscUpdate: async (applicant, data) => {
        return axios.put(Links.applicants.nyscUpdate + '/' + applicant, data)
    },

    /**
     * @description Updates applicant qualificationmdata
     * @param {string} applicantId the applicant userId
     * @param {object} data a form data containning results(a stringified javascript object) and files(an array of files)
     * @returns
     */
    qualificationUpdate: async (applicantId, data) => {
        return axios.put(Links.applicants.qualificationUpdate + '/' + applicantId, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    /**
     * update programme of the applicant
     * @param {string} applicant the applicant userId
     * @param {object} data information to be updated
     * @returns
     */
    programmeUpdate: async (applicant, data) => {
        return axios.put(Links.applicants.programmeUpdate + '/' + applicant, data)
    },

    /**
     * fetch utme details for a specific applicant
     * @param {string} applicant the applicant userId
     * @returns {Promise<object>}
     */
    getUTMEDetails: async (applicant) => {
        return axios.get(Links.applicants.getUTMEDetails + '/' + applicant)
    },

    /**
     * fetch SSCE details for a specific applicant
     * @param {string} applicant the applicant userId
     * @returns {Promise<object>}
     */
    getSSCEDetails: async (applicant) => {
        return axios.get(Links.applicants.getSSCEDetails + '/' + applicant)
    },

    /**
     * fetch NYSC details for a specific applicant
     * @param {string} applicant the applicant userId
     * @returns {Promise<object>}
     */
    getNYCEDetails: async (applicant) => {
        return axios.get(Links.applicants.getNYCEDetails + '/' + applicant)
    },

    /**
     * fetch applicant qualifications
     * @param {string} applicant the applicant userId
     * @returns {Promise<object>}
     */
    getQualificationDetails: async (applicant) => {
        return axios.get(Links.applicants.getQualificationDetails + '/' + applicant)
    },

    /**
     * handles fee transaction for an applicant
     * @param {string} applicant the applicant userId
     * @returns {Promise<object>}
     */
    initializeApplicationFeeTransaction: async (applicant) => {
        return axios.put(Links.applicants.initializeApplicationFeeTransaction + '/' + applicant)
    },

    /**
     * handles screening transaction for a specific applicant
     * @param {string} applicant the applicant userId
     * @returns {Promise<object>}
     */
    initializeScreeningTransaction: async (applicant) => {
        return axios.put(Links.applicants.initializeScreeningFeeTransaction + '/' + applicant)
    },

    /**
     * handles acceptance fee transaction
     * @param {string} applicant the applicant userId
     * @returns {Promise<object>}
     */
    initializeAcceptanceFeeTransaction: async (applicant) => {
        return axios.put(Links.applicants.initializeAcceptanceFeeTransaction + '/' + applicant)
    },

    /**
     * handles uploading of UTME details
     * @param {Blob} data
     * @returns {Promise<Blob>}
     */
    uploadUTME: async (data) => {
        return axios.post(Links.applicants.uploadUTME, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    /**
     * handles uploading of SSCE details
     * @param {Blob} data blob data to be uploaded
     * @returns {Promise<Blob>}
     */
    uploadSSCE: async (data) => {
        return axios.post(Links.applicants.uploadSSCE, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    /**
     * handles uploading of applicants information
     * @param {Blob} data blob data to be uploaded
     * @returns {Promise<Blob>}
     */
    uploadInformation: async (data) => {
        return axios.post(Links.applicants.uploadInformation, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    /**
     * handles uploading of applicants status
     * @param {Blob} data blob data to be uploaded
     * @param {Blob} session blob session to be uploaded
     * @returns
     */
    uploadStatus: async (data, session) => {
        return axios.post(Links.applicants.uploadStatus + '/' + session, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    /**
     * handles applicants qualifications
     * @param {Blob} data form data containing session(session id) and file(excel file)
     * @returns {Promise<Blob>} upload report
     */
    uploadQualifications: async (data) => {
        return axios.post(Links.applicants.uploadQualifications, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    /**
     * handles qualification documents
     * @param {string} applicantId the userId of the applicant
     * @param {Blob} data form data containing applicant(db id) and files(map documents)
     * @returns {Promise<Blob>}
     */
    uploadQualificationsDocuments: async (applicantId, data) => {
        return axios.post(Links.applicants.uploadQualificationsDocuments + '/' + applicantId, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    /**
     * handles record sheet download, depending on the selected session, faculty, department, programme, counry, state, lga and gender
     * @param {string} reqParams
     * @returns {Promise<Blob>}
     */
    recordSheet: async (reqParams) => {
        let params = '?session=' + reqParams.session
        if(reqParams.status){
            params = params +  '&student_status=' + reqParams.status
        }
        if(reqParams.faculty){
            params = params +  '&faculty=' + reqParams.faculty
        }
        if(reqParams.department){
            params = params +  '&department=' + reqParams.department
        }
        if(reqParams.programme){
            params = params +  '&programme=' + reqParams.programme
        }
        if(reqParams.country){
            params = params +  '&country=' + reqParams.country
        }
        if(reqParams.state){
            params = params +  '&state=' + reqParams.state
        }
        if(reqParams.lga){
            params = params +  '&lga=' + reqParams.lga
        }
        if(reqParams.gender){
            params = params +  '&gender=' + reqParams.gender
        }

        return axios.get(Links.applicants.recordSheet + params, {responseType: 'blob'})
    },

    /**
     * fetch qualification document for a specific applicant
     * @param {string} applicantId the userId of the applicant
     * @returns {Promise<Blob>}
     */
    qualificationDocumentsFor: async (applicantId) => {
        return axios.get(Links.applicants.qualificationDocument + '?applicant=' + applicantId, {responseType: 'blob'})
    },

    /**
     * download SSCE screening record sheet
     * @param {string} reqParams depending on the selected session, faculty, department, programme, counry, state, lga and gender
     * @returns {Promise<Blob>}
     */
    ssceScreeningReportSheet: async (reqParams) => {
        let params = '?session=' + reqParams.session
        if(reqParams.status){
            params = params +  '&student_status=' + reqParams.status
        }
        if(reqParams.faculty){
            params = params +  '&faculty=' + reqParams.faculty
        }
        if(reqParams.department){
            params = params +  '&department=' + reqParams.department
        }
        if(reqParams.programme){
            params = params +  '&programme=' + reqParams.programme
        }
        if(reqParams.country){
            params = params +  '&country=' + reqParams.country
        }
        if(reqParams.state){
            params = params +  '&state=' + reqParams.state
        }
        if(reqParams.lga){
            params = params +  '&lga=' + reqParams.lga
        }
        if(reqParams.gender){
            params = params +  '&gender=' + reqParams.gender
        }
        return axios.get(Links.applicants.ssceScreeningReportSheet + params, {responseType: 'blob'})
    },

    /**
     * handles download qualificationReport sheet
     * @param {string} reqParams depending on the selected session, faculty, department, programme, counry, state, lga and gender
     * @returns {Promise<Blob>}
     */
    qualificationReportSheet: async (reqParams) => {
        let params = '?session=' + reqParams.session
        if(reqParams.status){
            params = params +  '&student_status=' + reqParams.status
        }
        if(reqParams.faculty){
            params = params +  '&faculty=' + reqParams.faculty
        }
        if(reqParams.department){
            params = params +  '&department=' + reqParams.department
        }
        if(reqParams.programme){
            params = params +  '&programme=' + reqParams.programme
        }
        if(reqParams.country){
            params = params +  '&country=' + reqParams.country
        }
        if(reqParams.state){
            params = params +  '&state=' + reqParams.state
        }
        if(reqParams.lga){
            params = params +  '&lga=' + reqParams.lga
        }
        if(reqParams.gender){
            params = params +  '&gender=' + reqParams.gender
        }
        return axios.get(Links.applicants.qualificationReportSheet + params, {responseType: 'blob'})
    },

    /**
     * reject admission request
     * @param {string} applicantid applicant userId
     * @returns {Promise<boolean>}
     */
    rejectAdmission: async (applicantid) => {
        return axios.put(Links.applicants.rejectAdmission + '/' + applicantid)
    },

    /**
     * accept admission request
     * @param {string} applicantId applicant userId
     * @returns {Promise<boolean>}
     */
    acceptAdmission: async (applicantId) => {
        return axios.put(Links.applicants.acceptAdmission + '/' + applicantId)
    },

    /**
     * handles next of contact details update
     * @param {string} applicantId applicant userId
     * @returns {Promise<object>}
     */
    nextToContactDetails: async (applicantId) => {
        return axios.put(Links.applicants.nextToContactDetails + '/' + applicantId)
    },

    /**
     * handles next of kin details update for an applicant
     * @param {string} applicantId applicant userId
     * @returns {Promise<object>}
     */
    nextToNextOfKinDetails: async (applicantId) => {
        return axios.put(Links.applicants.nextToNextOfKinDetails + '/' + applicantId)
    },

    /**
     * handles next programme update for an applicant
     * @param {string} applicantId applicant userId
     * @returns {Promise<object>}
     */
    nextToProgrammeUpdate: async (applicantId) => {
        return axios.put(Links.applicants.nextToProgrammeUpdate + '/' + applicantId)
    },

    /**
     * handles applicant fee update
     * @param {string} applicantId applicant userId
     * @returns {Promise<object>}
     */
    nextToApplicationFee: async (applicantId) => {
        return axios.put(Links.applicants.nextToApplicationFee + '/' + applicantId)
    },

    /**
     * handles applicant screening fee update
     * @param {string} applicantId applicant userId
     * @returns {Promise<object>}
     */
    nextToScreeningFee: async (applicantId) => {
        return axios.put(Links.applicants.nextToScreeningFee + '/' + applicantId)
    },

    /**
     * handles next UTME update
     * @param {string} applicantId applicant userId
     * @returns {Promise<object>}
     */
    nextToUTMEUpdate: async (applicantId) => {
        return axios.put(Links.applicants.nextToUTMEUpdate + '/' + applicantId)
    },

    /**
     * handles next SSCE update
     * @param {string} applicantId applicant userId
     * @returns {Promise<object>}
     */
    nextToSSCEUpdate: async (applicantId) => {
        return axios.put(Links.applicants.nextToSSCEUpdate + '/' + applicantId)
    },

    /**
     * handles next NYSC update
     * @param {string} applicantId applicant userId
     * @returns {Promise<object>}
     */
    nextToNYSCUpdate: async (applicantId) => {
        return axios.put(Links.applicants.nextToNYSCUpdate + '/' + applicantId)
    },

    /**
     * handles next Qualification update
     * @param {string} applicantId applicant userId
     * @returns {Promise<object>}
     */
    nextToQualificationUpdate: async (applicantId) => {
        return axios.put(Links.applicants.nextToQualificationUpdate + '/' + applicantId)
    },

    /**
     * handle clearing of applicant
     * @param {string} applicantId applicant userId
     * @param {object} data clear applicant
     * @returns {Promise<boolean>}
     */
    toggleClear: async (applicantId, data) => {
        return axios.put(Links.applicants.toggleClear + '/' + applicantId, data)
    },

    /**
     * handle revoking of applicant
     * @param {string} applicantId applicant userId
     * @returns {Promise<boolean>}
     */
    revokeClearance: async (applicantId) => {
        return axios.put(Links.applicants.revokeClearance + '/' + applicantId)
    },

    /**
     * @description Download application fee invoice
     * @param {string} applicantId applicant userId
     * @returns {Promise<Blob>}
     */
    applicationInvoice: async (applicantId) => {
        return axios.get(Links.applicants.applicationInvoice + '/' + applicantId, {
            responseType: 'blob'
        })
    },

    /**
     * @description Download screening fee invoice
     * @param {string} applicantId applicant's userId
     * @returns {Promise<Blob>}
     */
    screeningInvoice: async (applicantId) => {
        return axios.get(Links.applicants.screeningInvoice + '/' + applicantId, {
            responseType: 'blob'
        })
    },

    /**
     * @description Download acceptance fee invoice
     * @param {string} applicantId applicant userId
     * @returns {Promise<Blob>}
     */
    acceptanceInvoice: async (applicantId) => {
        return axios.get(Links.applicants.acceptanceInvoice + '/' + applicantId, {
            responseType: 'blob'
        })
    },

    /**
     * @description Downloads application summary for applicant
     * @param {string} applicant applicant userId
     * @returns {Promise<Blob>}
     */
     applicationSummary: async (applicant) => {
        return axios.get(Links.applicants.applicationSummary + '?applicant=' + applicant, {
            responseType: 'blob'
        })
    },

    /**
     * @description Downloads screening form for applicant
     * @param {string} applicantId applicant userId
     * @returns {Promise<Blob>}
     */
     screeningForm: async (applicantId) => {
        return axios.get(Links.applicants.screeningForm + '?applicant=' + applicantId, {
            responseType: 'blob'
        })
    },

    /**
     * update applicant status
     * @param {string} applicantId applicant userId
     * @param {object} data object containing following props: status(number)
     * @returns {Promise<object>}
     */
    updateApplicantStatus: async (applicantId, data) => {
        return axios.put(Links.applicants.updateStatus + '/' + applicantId, data)
    },

    /**
     * update applicant popmts
     * @param {number} applicantId applicant id
     * @returns {Promise<object>}
     */
    updatePrompt: async (applicantId) => {
        return axios.put(Links.applicants.updatePrompted + '/' + applicantId)
    },

    /**
     * @description Updates applicants phone number
     * @param {string} applicantId applicant userId
     * @param {object} data an object containing one attribute: phone
     * @returns {Promise<Blob>}
     */
    updatePhone: async (applicantId, data) => {
        return axios.put(Links.applicants.updatePhone + '/' + applicantId, data)
    },

    /**
     * @description Find an applicant
     * @param {string} programmeTypeId programmeType id
     * @param {string} query the applicant name to find
     * @returns {Promise<object>}
     */
    find: async (session, query) => {
        return axios.get(Links.applicants.find + '?session=' + session + '&q=' + query)
    },

    /**
     * @description Steps an applicant up
     * @param applicantId applicant name to find
     * @param {object} data information of the applicant to be step up
     * @returns {Promise<object>}
     */
    stepApplicant: async (applicantId, data) => {
        return axios.put(Links.applicants.stepApplicant + '/' + applicantId, data)
    },

    /**
     * @description Updates an applicant
     * @param {string} applicantId User ID of the applicant to update
     * @param {object} data an object containing the ffg: firstName, lastName, middleName, contactAddress, homeTown, phone,
     *                      dob(Date of birth), gender(Gender id), lga(LGA id), contactLga(LGA id)
     * @returns {Promise<object>}
     */
    updateApplicant: async (applicantId, data) => {
        return axios.put(Links.applicants.updateApplicant + '/' + applicantId, data)
    },

    /**
     * handles screening of an applicant
     * @param {string} applicantId the applicant resource id
     * @param {object} data an object containin the following: reason(String) and status (numeric value of disqualify or qualify)
     * @returns {Promise<object>}
     */
    screenApplicant: async (applicantId, data) => {
        return axios.put(Links.applicants.screenApplicant + '/' + applicantId, data)
    },

    /**
     * @description Api to run a check on applicant subject combinations and compute their PUTME Score
     * @param {string} applicantId the applicant userId
     * @returns {Promise<object>}
     */
    runCombinationCheck: async (applicantId) => {
        return axios.get(Links.applicants.runCombinationCheck + '?applicant=' + applicantId)
    },

    /**
     * @description Resets applicant's application
     * @param {object} data an object containing the applicant userid(as applicant) and admission resource id(as admission)
     * @returns {Promise<object>}
     */
    resetApplication: async (data) => {
        return axios.post(Links.applicants.resetApplication, data)
    },

    /**
     * @description Upgrades applicant to a student
     * @param {object} data an object containing the applicant id(applicant's userId)
     * @returns {Promise<object>}
     */
    upgradeApplicantToStudent: async (data) => {
        return axios.post(Links.applicants.upgradeToStudent, data)
    },

    /**
     * @description Generate userId for student if no one exist
     * @param {object} data an object containing the applicant id(applicant's userId)
     * @returns {Promise<object>}
     */
    generateId: async (data) => {
        return axios.post(Links.applicants.generateId, data)
    },

    /**
     * update applicant start details information
     * @param {string} levelId level id
     * @param {string} sessionId session id
     * @param {string} programme progamme id
     * @param {string} applicant applicant userId
     * @returns {Promise<object>}
     */
    updateStartDetails: async (levelId, sessionId, programme, applicant) => {
        return axios.post(Links.applicants.updateStartDetails, {
            level: levelId,
            session: sessionId,
            programme: programme,
            applicant: applicant
        })
    },

    /**
     * @description Updates applicants jamb reg
     * @param {string} reg Applicant ID or Jamb reg of the applicant(if applicant ID is not available for applicant yet)
     * @param {string} newJambReg The new jamb reg to update to
     * @returns {Promise<object>}
     */
    updateJambReg: async (reg, newJambReg) => {
        return axios.post(Links.applicants.updateJambReg, {
            applicant: reg,
            newJambReg: newJambReg
        });
    },

    fixDataUpload: async () => {
        return axios.post(Links.applicants.fixDataUpload)
    }
}

export default Applicants;
