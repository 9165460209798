// user state
import axios from "axios";
import Links from "@/shared/constants/Links";

const state = {
    user: null,
    super: false,
    global: {},
}

const actions = {
    async loadGlobalConfig(){
        return await axios.get(Links.global.fetch)
    },
}

const getters = {
    getUser: state => state.user,
    getGlobal: state => state.global,
}

const mutations = {
    setCurrentUser: (state, data) => {
        state.user = data.user
        if(data.superOption1)
            state.super = data.superOption
    },
    setGlobal: (state, data) => (state.global = data),
}

export default {
    state,
    actions,
    getters,
    mutations
}
