import axios from "axios";
import Links from "shared/constants/Links";


/**
 * This is the Student Progress Status API hook for handling Student Progress Status in the system
 * (C) 2021-2023
 *
 * */

const StudentProgressStatus = {
    /**
     * create a student progress status
     * @param {object} data
     * @returns
     */
    create: async (data) => {
        return axios.post(Links.studentProgressStatus.create, data)
    },

    /**
     * update student progress status 
     * @param {string} id the selected student progress status id
     * @param {object} data the information to be updated with
     * @returns
     */
    update: async (id, data) => {
        return axios.put(Links.studentProgressStatus.update + '/' + id, data)
    },

    /**
     * trash the student progress status
     * @param {string} id the selected student progress status 
     * @returns 
     */
    delete: async (id) => {
        return axios.delete(Links.studentProgressStatus.delete + '/' + id);
    },

    /**
     * fetch all progress status students and their details
     * @param programmeType the selected programmetype id
     * @returns
     */
    all: async (programmeType) => {
        return axios.get(Links.studentProgressStatus.all + '?programme_type=' + programmeType);
    },

    /**
     * fetch all students with student progress 
     * @param {string} status the selected sttudent progress status
     * @param {string} page the page number e.g 1
     * @param {string} size the size of the page 10
     * retrive all postgraduate students(details not required)
     * @returns
     */
    allstudents: async (reqParam, page, size) => {
        let status = '?progress_status=' + reqParam;
        return axios.get(Links.studentProgressStatus.allStudents + status + "&page=" + page+"&size=" + size);
    },

    /**
     * initiate payment
     * @param studentId the selected student id
     * @param statusId the selected student status
     * @returns {Promise<AxiosResponse<any>>}
     */
    initializePayment: async (studentId, statusId) => {
        return axios.post(Links.studentProgressStatus.initializepayment, {
            studentProgressStatus: statusId,
            student: studentId
        });
    },

    /**
     * retrive all pendingpayments
     * @returns
     */
    getpendingpayments: async (userId) => {
        return axios.get(Links.studentProgressStatus.pendingpayments + "?student=" + userId);
    },

    /**
     * 
     * @returns
     */
    nextStatusForStudent: async (userId) => {
        return axios.get(Links.studentProgressStatus.getnextforstudent + "?student=" + userId);
    },

    /**
     * update the student progress status
     * @param {object} data the information to be updated
     * @returns
     */
    updatestatus: async (data) => {
        return axios.post(Links.studentProgressStatus.updatestatus, data);
    },


    /**
     * fetch the invoice (.pdf)
     * @param {string} invoice 
     * @returns {Promise<Blob>}
     */
    invoice: async(invoice) => {
        return axios.get(Links.studentProgressStatus.invoice + "?invoice=" + invoice, {
            responseType: 'blob'
        })
    }
};


export default StudentProgressStatus;
