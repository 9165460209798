import axios from 'axios'
import Links from '../constants/Links'; 


/**
 * This is the AuditTrail API hook that handles all system activities (C) 2021-2023
 *  
 */

const AuditTrail = {
    /**
     * @description Fetch and returns audit trails result
     * @param {string} page the page to fetch
     * @param {string} size size of data to return e.g 10
     * @param {Date} date if specified, only activities that happens on that day will be fetched, format is (YYY-MM-DD)
     * 
     * @returns {Promise<object>} Paginated result of audit trails
     */
    getAll: async (page, size, date) => {
        return axios.get(Links.auditTrail.all + '?page=' + page + '&size=' + size + (date ? '&date=' + date : ''))
    }
}

export default AuditTrail